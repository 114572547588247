// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yMJ1GDAs{margin-top:var(--spacing-lg)}.y9J4\\+Li6{display:block;font-size:1rem;font-weight:500;line-height:1.4;margin-bottom:var(--spacing-md)}.Q4Njb2h0{align-items:flex-start;display:flex;flex-direction:column;gap:.2rem}.QDi6NiNt{align-items:center;background-color:hsla(0,0%,100%,.8);border-radius:var(--border-radius);display:inline-flex;max-width:17rem;padding:.3rem .6rem}.XbUGMseU{font-size:.81rem;line-height:1;margin-right:.5rem}.j6kEhOgK{color:var(--color-blue);font-weight:900;margin-left:.3rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"rating": "yMJ1GDAs",
	"ratingTitle": "y9J4+Li6",
	"ratingItems": "Q4Njb2h0",
	"region": "QDi6NiNt",
	"regionName": "XbUGMseU",
	"regionCongratulations": "j6kEhOgK"
};
module.exports = ___CSS_LOADER_EXPORT___;
