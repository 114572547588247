
import Vue from "vue"
import type { Ball } from "@/types/api/congratulation"

export default Vue.extend({
  props: {
    balls: { type: Array as VuePropType<Ball[]>, default: () => [] },
  },

  data: () => ({
    count: 5,
  }),
})
