// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XroWqPME{align-items:flex-start;background:url(/landing/dotted.svg) 0/auto 65% no-repeat;display:flex;flex-direction:column;white-space:nowrap}.RxtUWqeo{gap:var(--spacing-xs)}.RxtUWqeo,.Xs8OTGlI{align-items:center;display:flex}.Xs8OTGlI{background:url(/landing/bush.png) 50%/contain no-repeat;height:max(3.75rem,min(4.17vw,5rem));justify-content:center;width:max(3.75rem,min(4.17vw,5rem))}.vzTjXTCT{align-items:center;border:2px solid var(--color-red);border-radius:50%;color:var(--color-bg);display:flex;font-size:1.25rem;font-weight:900;height:2.6rem;justify-content:center;position:relative;top:2px;width:2.6rem}._3ml0gSm8{color:var(--color-blue);font-weight:900;text-transform:uppercase}.cKw6a8Dk{font-size:.75rem}@media(max-width:1229.98px){.XroWqPME{margin-bottom:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"dates": "XroWqPME",
	"datesItem": "RxtUWqeo",
	"datesNumber": "Xs8OTGlI",
	"datesNumberValue": "vzTjXTCT",
	"datesDay": "_3ml0gSm8",
	"datesText": "cKw6a8Dk"
};
module.exports = ___CSS_LOADER_EXPORT___;
