
import Vue from "vue"
import random from "lodash/random"
import { mapState, mapGetters } from "vuex"
import type { Ball } from "@/types/api/congratulation"

export default Vue.extend({
  data: () => ({
    balls: [] as Ball[],
    sortedBalls: [] as Ball[],
    modalOpened: false,
    // target: 1000000,
    target: 1000000000, // миллиард чтоб не превысить лимит
    interval: undefined as Timer,
    intervalTime: 5, // В секундах
    treeZoomed: false,
    treeHover: false,
    bubbleColorHsl: [226, 77, 51],
    bubbleColorInterval: undefined as Timer,
  }),

  async fetch () {
    await this.fetchCongratulations()
  },

  computed: {
    ...mapState("app", ["isNewYear"]),
    ...mapGetters("user", ["isAdmin"]),

    bubbleColor (): string {
      const [h, s, l] = this.bubbleColorHsl
      return `hsla(${h}deg, ${s}%, ${l}%, 0.5)`
    },

    congratulations (): number {
      return this.balls.reduce((acc, curr) => acc + curr.congratulationCount, 0)
    },
  },

  mounted () {
    this.interval = setInterval(async () => {
      await this.fetchCongratulations()
    }, this.intervalTime * 1000)

    if (this.isNewYear)
      this.bubbleColorInterval = setInterval(() => {
        this.bubbleColorHsl = [random(0, 360), random(42, 98), random(40, 90)]
      }, 1000)
  },

  beforeDestroy () {
    clearInterval(this.interval)
    clearInterval(this.bubbleColorInterval)
  },

  methods: {
    async fetchCongratulations () {
      if (this.congratulations >= this.target) {
        clearInterval(this.interval)
        return
      }

      const [res, err] = await this.$api.congratulation.getBalls({ limit: 100 })
      if (err) {
        console.error(err)
      } else if (res) {
        if (!res.items || !res.items.length) return
        this.balls = [...res.items].sort((a: Ball, b: Ball) => b.name < a.name ? 1 : -1)
        this.sortedBalls = [...res.items].sort((a: Ball, b: Ball) => b.congratulationCount - a.congratulationCount)
      }
    },
  },
})
