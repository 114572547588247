
import Vue from "vue"
import { mapState } from "vuex"
import type { Ball } from "@/types/api/congratulation"

export default Vue.extend({
  props: {
    balls: { type: Array as VuePropType<Ball[]>, default: () => [] },
    zoomed: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
  },

  data: () => ({
    // Координаты для 89 шариков на елке
    ballsCoords: [
      { x: 50, y: 10 },
      { x: 45, y: 13 },
      { x: 41, y: 18 },
      { x: 52, y: 17 },
      { x: 47, y: 21 },
      { x: 42, y: 24 },
      { x: 52, y: 25 },
      { x: 56, y: 23 },
      { x: 47, y: 29 },
      { x: 58, y: 27 },
      { x: 38, y: 28 },
      { x: 42, y: 32 },
      { x: 52, y: 31 },
      { x: 63, y: 32 },
      { x: 35, y: 33 },
      { x: 30, y: 37 },
      { x: 35, y: 39 },
      { x: 43, y: 37 },
      { x: 49, y: 38 },
      { x: 57, y: 36 },
      { x: 63, y: 38 },
      { x: 28, y: 45 },
      { x: 34, y: 44 },
      { x: 42, y: 42 },
      { x: 44, y: 46 },
      { x: 48, y: 43 },
      { x: 53, y: 41 },
      { x: 57, y: 44 },
      { x: 63, y: 43 },
      { x: 71, y: 47 },
      { x: 63, y: 48 },
      { x: 50, y: 49 },
      { x: 38, y: 48 },
      { x: 31, y: 48 },
      { x: 24, y: 54 },
      { x: 31, y: 55 },
      { x: 37, y: 54 },
      { x: 43, y: 52 },
      { x: 47, y: 55 },
      { x: 53, y: 54 },
      { x: 58, y: 53 },
      { x: 64, y: 54 },
      { x: 69, y: 51 },
      { x: 72, y: 56 },
      { x: 66, y: 60 },
      { x: 59, y: 57 },
      { x: 50, y: 60 },
      { x: 42, y: 60 },
      { x: 37, y: 61 },
      { x: 27, y: 59 },
      { x: 20, y: 63 },
      { x: 27, y: 64 },
      { x: 32, y: 67 },
      { x: 40, y: 65 },
      { x: 47, y: 64 },
      { x: 60, y: 63 },
      { x: 68, y: 65 },
      { x: 74, y: 64 },
      { x: 80, y: 67 },
      { x: 75, y: 68 },
      { x: 67, y: 71 },
      { x: 60, y: 69 },
      { x: 53, y: 69 },
      { x: 47, y: 70 },
      { x: 40, y: 71 },
      { x: 30, y: 73 },
      { x: 25, y: 70 },
      { x: 18, y: 72 },
      { x: 18, y: 78 },
      { x: 12, y: 80 },
      { x: 14, y: 87 },
      { x: 21, y: 84 },
      { x: 27, y: 80 },
      { x: 29, y: 86 },
      { x: 35, y: 76 },
      { x: 42, y: 76 },
      { x: 50, y: 76 },
      { x: 57, y: 77 },
      { x: 67, y: 78 },
      { x: 74, y: 75 },
      { x: 84, y: 75 },
      { x: 79, y: 79 },
      { x: 86, y: 83 },
      { x: 79, y: 86 },
      { x: 73, y: 84 },
      { x: 65, y: 84 },
      { x: 56, y: 84 },
      { x: 52, y: 80 },
      { x: 46, y: 82 },
    ],
    zoom: false,
  }),

  computed: {
    ...mapState("app", ["isNewYear"]),
  },
})
